import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './skill-pages.scss';
import lodash from 'lodash';
import { IBCMSkillPage } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { BCMSkillPage } from '../../modules/bcm/common/components/bcm-skillpage';

interface IBCMSkillPageNodes {
  nodes: IBCMSkillPage[];
}

interface IBCMSkillPageEntry {
  allCharacters: IBCMSkillPageNodes;
}

interface IProps {
  data: IBCMSkillPageEntry;
}

const BCMSkillPagesPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search skill pages...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_attacker.png"
                width={24}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Debuffer',
            tooltip: 'Debuffer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_debuffer.png"
                width={24}
                alt="Debuffer"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_defender.png"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'Healer',
            tooltip: 'Healer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_healer.png"
                width={24}
                alt="Healer"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_supporter.png"
                width={24}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'dropdown',
        values: [
          { label: 'Any type', value: 'all' },
          {
            label: 'Character',
            value: 'Character'
          },
          {
            label: 'Generic',
            value: 'Generic'
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke skill-bcm'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>Skill pages</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/blackclover/categories/cat_page.jpg"
            alt="Page"
          />
        </div>
        <div className="page-details">
          <h1>Black Clover Mobile Skill Pages</h1>
          <h2>List of available skill pages in Black Clover Mobile!</h2>
          <p>
            Last updated: <strong>30/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Skill Pages"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} skill page
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar bcm">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="skill-simplified-view">
        {visibleCharacters.map((emp, index) => {
          return (
            <div className="single-ego skill" key={index}>
              <BCMSkillPage slug={emp.slug} mode="data" />
            </div>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/blackclover/no_results.png"
            alt="No results"
          />
          <p>No skill pages found. Try changing your filter. </p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default BCMSkillPagesPage;

export const Head: React.FC = () => (
  <Seo
    title="Skill pages | Black Clover M | Prydwen Institute"
    description="List of available skill pages in Black Clover Mobile!"
    game="bcm"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulBlackCloverSkillPage(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        skillPageId
        name
        slug
        class
        rarity
        type
        isNew
        isSeasonal
        season
      }
    }
  }
`;
