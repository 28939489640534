import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IBCMSkillPage } from '../../../common/model/graphql-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import { BCMCharacter } from './bcm-character';

interface IProps {
  employee: IBCMSkillPage;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const BCMSkillPageData: React.FC<IProps> = ({ employee }) => {
  const image = employee.image
    ? getImage(employee.image.localFile.childImageSharp)
    : null;
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className={`avatar bcm ${employee.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${employee.name}`}
              />
            )}
          </div>
          <div className="information">
            <h2 className="name">
              {employee.name}{' '}
              {employee.season && (
                <span className="tag season">[S{employee.season}]</span>
              )}
            </h2>
            <div className="details">
              <p>
                Rarity:{' '}
                <strong className={`${employee.rarity}`}>
                  {employee.rarity}
                </strong>
              </p>
              <p>
                Class: <strong>{employee.class}</strong>
              </p>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Tabs defaultActiveKey="info" id={employee.slug} className="mb-3">
            <Tab eventKey="info" title="Information">
              <h5>Passive</h5>
              <div className={`skill-with-coloring`}>
                {renderRichText(employee.passive, options)}
              </div>
              {employee.character ? (
                <>
                  <h5>Dedicated character</h5>
                  <p>
                    This Skill Page will upgrade one of this character skills
                    (it won't do anything for other characters):
                  </p>
                  <BCMCharacter
                    mode="icon"
                    slug={employee.character.slug}
                    enablePopover
                    showLabel
                  />
                </>
              ) : (
                <>
                  <h5>Generic Skill Page</h5>
                  <p>
                    This skill page is a Generic one and can be equipped by any
                    character from <strong>{employee.class}</strong> class.
                  </p>
                </>
              )}
            </Tab>
          </Tabs>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
