import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IBCMSkillPage } from '../../../common/model/graphql-types';
import { BCMSkillPageIcon } from './bcm-skillpage-icon';
import { BCMSkillPageData } from './bcm-skillpage-data';

interface IProps {
  id?: string;
  slug: string;
  showLabel?: boolean;
  showTags?: boolean;
  showIcon?: boolean;
  mode: string;
  skillPageId?: string;
  variant?: string;
}

export const BCMSkillPage: React.FC<IProps> = ({
  id,
  skillPageId,
  mode,
  showLabel,
  slug,
  showTags,
  showIcon
}) => {
  const allBCMCharacters = useStaticQuery(graphql`
    query {
      allContentfulBlackCloverSkillPage {
        nodes {
          id
          skillPageId
          name
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          character {
            name
            slug
          }
          passive {
            raw
          }
          class
          rarity
          type
          isNew
          isSeasonal
          season
        }
      }
    }
  `);
  if ((!id && !slug && !skillPageId) || !mode) {
    return <div>Err</div>;
  }

  const character =
    allBCMCharacters.allContentfulBlackCloverSkillPage.nodes.find(
      (emp: IBCMSkillPage) =>
        id
          ? emp.id === id
          : skillPageId
          ? emp.skillPageId === skillPageId
          : emp.slug === slug
    );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <BCMSkillPageIcon
          employee={character}
          showLabel={showLabel}
          showTags={showTags}
          showIcon={showIcon}
        />
      )}
      {mode === 'data' && <BCMSkillPageData employee={character} />}
    </>
  );
};
